import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { lime } from "@material-ui/core/colors";
import Stack from '@mui/material/Stack';

const defaultMaterialTheme = createTheme({
    palette: {
        primary: lime,
    },
});

export default function Scheduler({
    minDate,
    maxDate,
    startHour,
    endHour,
    setSnackbarMessage,
    setSnackbarOpen,
    setSnackbarType,
    setEnablePayment,
    scheduledDelivery
}) {
    const [datetime, setDatetime] = React.useState(scheduledDelivery);

    const checkPickedDate = (value, event) => {
        // console.log(`startHour: ${startHour}`);
        // console.log(startHour);
        // console.log(`endHour: ${endHour}`);
        try {
            setDatetime(value);
            let pickedDatetime = JSON.stringify(value);
            if (pickedDatetime === 'null') {
                setSnackbarMessage('Please choose a valid date and time');
                setSnackbarType('error');
                setSnackbarOpen(true);
                setEnablePayment(false);
            } else {
                let now = new Date();
                if (now > value) {
                    setSnackbarMessage('Please choose a date/time in the future');
                    setSnackbarType('error');
                    setSnackbarOpen(true);
                    setEnablePayment(false);
                } else {
                    setEnablePayment(true);
                    localStorage.setItem('@appgenie/deliverySchedule', pickedDatetime);
                }
            }
        } catch (err) {
            setSnackbarMessage('An error has occurred');
            setSnackbarType('error');
            setSnackbarOpen(true);
            setEnablePayment(false);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
                <ThemeProvider theme={defaultMaterialTheme}>
                <DateTimePicker
                    // renderInput={(params) => <TextField {...params} size="small" />}
                    renderInput={(props) => <TextField {...props} size="small" />}
                    label="Schedule"
                    value={datetime}
                    onChange={(value, event) => {
                        checkPickedDate(value, event);
                    }}
                    // minutesStep={30}
                    minDate={minDate}
                    maxDate={maxDate}
                    minTime={new Date(0, 0, 0, startHour.hour)}
                    maxTime={new Date(0, 0, 0, endHour.hour, endHour.minute)}
                // format="DD-MM-YYYY"
                />
                </ThemeProvider>
            </Stack>
        </LocalizationProvider>
    );
}
